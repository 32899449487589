import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { cyan, indigo, red } from '@mui/material/colors'

export const defaultTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: indigo[900],
      },
      secondary: {
        main: cyan[700],
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#fff',
      },
    },
  }),
)
