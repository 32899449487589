import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSigninCheck } from 'reactfire'

type Props = {
  children: JSX.Element
}

export function SigninCheck(props: Props) {
  const router = useRouter()
  const { status, data: signInCheckResult } = useSigninCheck({
    suspense: false,
  })

  useEffect(() => {
    if (status === 'success') {
      if (!signInCheckResult.signedIn) {
        router.push('/signin')
      }
      signInCheckResult.user?.getIdTokenResult(true).then((idTokenResult) => {
        if (idTokenResult.claims.company_id) {
          if (!router.pathname.match(/^\/client/))
            router.push(`/client/${idTokenResult.claims.company_id}/dashboard`)
        } else if (router.pathname !== '/signin') {
          router.push('/signin')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInCheckResult])

  if (status === 'loading') {
    return null
  }

  return props.children
}
