import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FirebaseAppProvider } from 'reactfire'
import { RecoilRoot } from 'recoil'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { SigninCheck } from '@components/organisms/AuthCheck/SigninCheck'
import { SnackbarView } from '@components/organisms/Snackbar'
import { isBrowserDetect } from '@libs/isBrowserDetect'
import { defaultTheme } from '@styles/theme'
import firebaseConfig from '../firebase/firebaseConfig'

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const DynamicFirebaseAppIndividualProviders = dynamic(
  () => import('../components/common/FirebaseAppIndividualProviders'),
  { ssr: false },
)

const DynamicMyPageViewLogger = dynamic(() => import('../components/common/MyPageViewLogger'), {
  ssr: false,
})

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug AdminUser-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */

export function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    if (isBrowserDetect()) {
      window.scrollTo(0, 0)
    }
  }, [router.asPath])
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <DynamicFirebaseAppIndividualProviders>
          {/* @ts-ignore */}
          <RecoilRoot>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <SigninCheck>
                  <Component {...pageProps} />
                </SigninCheck>
                <SnackbarView />
              </ThemeProvider>
            </StyledEngineProvider>
            <React.Suspense fallback={<div />}>
              <DynamicMyPageViewLogger />
            </React.Suspense>
          </RecoilRoot>
        </DynamicFirebaseAppIndividualProviders>
      </FirebaseAppProvider>
    </>
  )
}

export default MyApp
